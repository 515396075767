import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import Wolflayout from '../components/wolflayout'
import { components } from '../slices'
import { StaticImage } from 'gatsby-plugin-image'
import { ParallaxProvider, useParallax } from 'react-scroll-parallax'

const TextComponent = () => {
  const { ref: parallaxRef } = useParallax({
    speed: -4
  })
  return (
    <div className="p-[25px] py-12 md:py-4 lg:p-[80px] bg-center uppercase">
      <p ref={parallaxRef} className="font-ohno text-white text-center text-sm lg:text-3xl md:mx-[10rem] xl:mx-[15rem]">Le Festif! de Baie Saint-Paul est un rassemblement incontournable de la scène musicale québécoise et internationale dont l’engagement local, l’éco-responsabilité, l’audace et la créativité (flirtant avec la magie) sont devenus les marques de commerce.</p>
    </div>
  )
}

const ButtonsComponent = () => {
  const { ref: parallaxRef } = useParallax({
    speed: -4
  })
  return (
    <div ref={parallaxRef} className="inline-grid grid-cols-3 grid-rows-3 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-12 md:ml-3 md:mr-3 ml-0.5 mr-0.5 lg:w-[calc(100%-12rem)] lg:ml-24 lg:mr-24 max-w-[1500px]">
      <a className="landing-page-buttons landing-page-buttons-double bg-red-wolf2024 row-span-2 col-span-1 px-3" href="/infos/appmobile#info_content">
        <div className="flex flex-col w-full">
          <p className="text-yellow-wolf2024 !w-full">TÉLÉCHARGE<br/>NOTRE APP<br/>POUR LES NOUVELLES</p>
          <div className="flex flex-col mt-4 w-full">
            <a href="https://apps.apple.com/ca/app/le-festif/id895648726" target="_blank" rel="noreferrer">
              <StaticImage className={'h-[40px]'}
                            src={'../images/stores/appstore-logo-yellow.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo App Store"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US" target="_blank" rel="noreferrer">
              <StaticImage className={'h-[40px] mt-2'}
                            src={'../images/stores/googleplay-logo-yellow.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo Google Play Store" />
            </a>
          </div>
        </div>
      </a>
      <a className="landing-page-buttons bg-blue-wolf2024 row-span-1 col-span-1" href="/infos/parvisdufestif#info_content">
        <p className="text-purple-wolf2024">LE PARVIS</p>
      </a>
      <a className="landing-page-buttons bg-green-wolf2024 row-span-1 col-span-1" href="/benevolat/">
        <p className="text-purple-wolf2024">BÉNÉVOLAT</p>
      </a>
      <a className="landing-page-buttons bg-yellow-wolf2024 row-span-1 col-span-1" href="/infos/scenes#info_content">
        <p className="text-purple-wolf2024">LES SCÈNES</p>
      </a>
      <a className="landing-page-buttons landing-page-buttons-double bg-blue-wolf2024 row-span-2 col-span-1" href="/developpement-durable">
        <div className="flex flex-col w-full">
          <p className="text-purple-wolf2024 hidden md:block">DÉVELOPPEMENT<br/>DURABLE</p>
          <p className="text-purple-wolf2024 md:hidden">DEV<br/>DURABLE</p>
          <StaticImage className={'h-[40px] mt-4'}
                        src={'../images/devdurable-logo.png'}
                        placeholder="blurred"
                        objectFit="contain"
                        alt="Logo Festif"/>
        </div>
      </a>
      <a className="landing-page-buttons bg-green-wolf2024 row-span-1 col-span-1" href="/programmation?filtre=Gratuit#calendrier">
        <p className="text-purple-wolf2024 block">SHOWS<br/>GRATUITS</p>
      </a>
      <a className="landing-page-buttons bg-red-wolf2024 row-span-1 col-span-1" href="/infos/zonedeservices#info_content">
        <p className="text-yellow-wolf2024">ZONE DE<br/>SERVICES</p>
      </a>
    </div>
  )
}

const IndexPage = ({ data, location }) => {
  if (!data) return null
  const doc = data.prismicHomepage.data
  const metaTitle = data.prismicHomepage.data.metatitle.text
  const metaDescription = data.prismicHomepage.data.metadescription.text
  const metaKeywords = data.prismicHomepage.data.metakeywords.text
  const ogImage = data.prismicHomepage.data.og_img.url

  return (
      <Wolflayout mobileTitle={'Le Festif!'}>
        <FestifHelmet title={metaTitle}
          description={metaDescription}
          keywords={metaKeywords}
          imageUrl={ogImage}
          href={location.href} />

        {/* Top image / video */}
        <div className="max-w-[1920px] justify-center mx-auto">
          <center>
            <div className="top-image text-center w-full px-8">
              <a href="/">
                <StaticImage alt='Visuel de remerciement' className="max-w-[2300px] max-h-[900px] transition-transform duration-300 transform custom-hover-scale" src="../images/main-page-top-image.png" placeholder="none"/>
              </a>
              {/* <video muted autoPlay loop playsInline>
                <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdmVEMTzAJOCiie_LeFestif_Prog2024.mov" type="video/mp4" />
              </video> */}
            </div>
          </center>
        </div>

        <div>
          <h1 className='text-center text-white font-ohno text-5xl my-20'>17 au 20 juillet 2025</h1>
        </div>

        <div className='stars text-center'>
          {/* BUTTONS */}
          <ParallaxProvider>
            <ButtonsComponent />
          </ParallaxProvider>

          {/* AFFICHE */}
          <div className="text-center">
            <StaticImage alt='Affiche artistes Le Festif!' className="w-full mx-auto" src="../images/wolf/affiche.png" />
          </div>

          {/* TEXTE */}
          <ParallaxProvider>
            <TextComponent />
          </ParallaxProvider>

          <div className='xl:mt-[-200px] mt-0'>
            <StaticImage className={'max-w-[1920px]'}
                          src={'../images/wolf/affiche-bottom.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Visuel floral"/>
          </div>
        </div>

        {/* PARTENAIRES */}
        <SliceZone slices={doc.body} components={components} />
      </Wolflayout>
  )
}

export const query = graphql`
  query {
    prismicHomepage {
      data {
        metadescription {
          text
        }
        metakeywords {
          text
        }
        metatitle {
          text
        }
        og_img {
          url
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyCarousel
          ...HomepageDataBodySponsors
          ...HomepageDataBodySponsorsHeader
        }
      }
    }
  }
`

export default IndexPage
